<template>
  <div id="product">
    <v-flex xs12 v-if="data">
      <v-layout wrap :class="isMobile ? 'product mt-12' : 'product mt-0'">
        <v-flex xs12 sm12 md6 style="position:relative;">
          <div v-if="!isMobile && data" style="position:sticky;top:10%">
            <ProductWeb :data="data" @countdown='handleFinishCountdown()' :playindicator="playindicator"/>
           </div>
          <ProductMobile
            v-if="isMobile && data"
            :data="data" 
            :isSuccess="success" 
            :updatedIsWishlist="updatedIsWishlist" 
            :playindicator="playindicator"
            @submit="setMySize($event)" 
            @share="share()" 
            @openRemind="openRemind($event)"
            @fullscreen="openSlider($event)"
            @countdown='handleFinishCountdown()'
            @addToCartBundling="addToCartBundling($event)" />
        </v-flex>
        <v-flex xs12 sm12 md6 v-if="data && !isMobile" id="p-detail">
          <div class="product-detail">
            <h1> {{ data.product_name }}</h1>

            <div style="float:right">
              <template v-if="!data.is_bundling">
                <v-btn v-if="updatedIsWishlist || data.is_wishlist" icon color="red">
                  <!-- <v-icon medium>fas fa-heart</v-icon> -->
                  <v-icon medium>mdi-heart</v-icon>
                </v-btn>

                <v-btn v-else icon color="black" @click="openRemind('Add to Wishlist')">
                  <!-- <v-icon medium>far fa-heart</v-icon> -->
                  <v-icon medium>mdi-heart-outline</v-icon>
                </v-btn>
              </template>

              <v-btn icon color="black" @click="share()">
                <!-- <v-icon medium>fas fa-share-alt</v-icon> -->
                <v-icon medium>mdi-share-variant</v-icon>
              </v-btn>
            </div>

            <p class="text"> 
              <span v-if="data.start_price == '0'" class="text-harga"> 
                 {{ data.selling_price === 0 ? '???' : addComma(data.selling_price) + ' IDR' }}
              </span>
              <span v-if="data.start_price != '0'" class="text-harga"> 
                {{ addComma(data.start_price) }} IDR - {{ addComma(data.end_price) }} IDR
              </span>
              <span class="text-c" v-if="data.harga_coret && data.harga_coret !== '0'"> / {{ addComma(data.harga_coret) }} IDR </span>
            </p>
            <p>
            <span class="text-sold mb-0" v-if="data.sold_qty && data.sold_qty !== '0' && brand === '9'">
              Terjual {{ data.sold_qty}} pcs
            </span>
            </p>
            <!-- <p class="text"> {{ data.product_code }} </p> -->
            <div class="text-d" v-html="data.description"></div>

            <!-- Not Bundling Package -->
            <div v-if="!data.is_bundling">
              <table class="bordered mt-9" v-if="showSizeList && (data && !data.countdown) && data.product_lable !== 'COMING-SOON'">
                <tr v-for="(item,index) in data.variant" :key="index" @click="chooseSize(item)">
                  <td v-if="data.start_price == '0'">{{ item.ukuran }}</td>
                  <td v-if="data.start_price != '0'">
                    <div style="display:flex;justify-content:space-between">
                      <div>{{ item.ukuran }}</div>
                      <div>IDR {{ addComma(item.selling_price)}}</div>
                    </div>
                  </td>
                </tr>
              </table>
              <table class="bordered mt-9 " @click="showSizeList = !showSizeList" v-if="!showSizeList && (data && !data.countdown) && data.product_lable !== 'COMING-SOON'">
                <tr class="choosed">
                  <td v-if="data.start_price == '0'">{{ mySize.ukuran }}</td>
                  <td v-if="data.start_price != '0'">
                    <div style="display:flex;justify-content:space-between">
                      <div>{{ mySize.ukuran }}</div>
                      <div>IDR {{ addComma(mySize.selling_price)}}</div>
                    </div>
                  </td>
                </tr>
              </table>
              <p class="text2" v-if="data && (!data.countdown || data.product_lable !== 'COMING-SOON')">{{ $t('shop.pick-variant') }}</p>
              <v-btn v-if="data.status === 'sold'" class="mt-5" tile color="black" width="100%" height="44px" @click="openRemind('Remind Me')">
                <span class="btn-text"> REMIND ME </span>
              </v-btn>
              <v-btn v-else class="mt-5" tile color="black" width="100%" height="44px" @click="checkIsCountDown()" :disabled="data.status === 'sold' || data.product_lable === 'COMING-SOON'">
                <span class="btn-text" v-if="data.status === 'sold' || data.product_lable === 'COMING-SOON'"> 
                  <b v-if="data.status === 'sold'"> {{ $t('shop.empty-product') }}</b>
                  <b v-if="data.product_lable === 'COMING-SOON'"> COMING SOON</b> 
                </span>
                <span class="btn-text" v-else> {{ $t('shop.add-to-cart') }} </span>
              </v-btn>
            </div>
            <!-- End of Not Bundling Package -->

            <!-- Bundling Package -->
            <div v-else>
              <v-btn v-if="data.status === 'sold'" class="mt-5" tile color="black" width="100%" height="44px" :disabled="true">
                <span class="btn-text"> SOLD </span>
              </v-btn>
              <div v-else>
                <BundlingLg :data="data" :success="successAddBundling" @addtocart="addToCartBundling($event)" />
              </div>
            </div>

            <div class="mt-8">
              <!-- <DekstopRating v-if="data && data.rating_star !== '0' "/> -->
              <!-- <DekstopRating :detail="data"/> -->
            </div>
          </div>
        </v-flex>
        <v-flex xs12>
          <div id="p-divider" style="width:10px; height:10px"></div>
        </v-flex>
        <v-flex xs10 offset-xs1 class="mt-9" id="p-slider" v-if="!isMobile && data && data.related_product">
          <h3> {{ data.judul_related }} </h3>
          <!-- <ProductSlider :data="data.related_product"/> -->
          <Recommendation :shop="data.related_product"/>

          <div id="p-slider-pointer" style="width:10px; height:10px"></div>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-dialog v-model="dialog"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="false"
    >
      <SocialMedia :data="data"/>
    </v-dialog>

    <v-dialog v-model="remindDialog"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="false"
    >
      <RemindMe v-if="remindDialog" :variants="data.variant_existing" :title="subtitle" @submit="remindMe($event)"/>
    </v-dialog>

    <v-dialog v-model="sliderDialog"
      :fullscreen="true"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="false"
    >
      <FullScreenSlider v-if="sliderDialog" :data="sliderImages" :options="sliderOptions" @close="sliderDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import apiCoversion from '@/api-coversion'
  import ProductMobile from '@/components/ProductMobile3'
  import ProductWeb from '@/components/ProductWeb3'
  import SocialMedia from '@/components/SocialMedia'
  // import ProductSlider from '@/components/ProductSlider'
  import RemindMe from '@/components/RemindMe'
  import FullScreenSlider from '@/components/FullScreenSlider'
  import BundlingLg from '@/components/Bundling/BundlingLg'
  // import DekstopRating from '@/components/Rating/Dekstop/DekstopRating'
  import Recommendation from '@/components/Recommendation/Recommendation'

  export default {
    name: 'Product',
    components: { ProductMobile, ProductWeb, SocialMedia, Recommendation, RemindMe, FullScreenSlider, BundlingLg},
    computed: {
      ...mapState([
        'isMobile',
        'cart',
        'cartNumber',
        'category',
        'token'
      ])
    },
    data () {
      return {
        brand: process.env.VUE_APP_BRAND,
        data: null,
        mySize: null,
        success: false,
        showSizeList: true,
        dialog: false,
        remindDialog: false,
        coversionResponse: '',
        subtitle: '',
        updatedIsWishlist: false,
        sliderDialog: false,
        sliderOptions: null,
        successAddBundling: false,
        playindicator: [],
        sliderImages: []
      }
    },
    methods: {
      handleFinishCountdown() {
        this.data.countdown = false
        this.getProduct(false)
      },
      openRemind (subtitle) {
        this.subtitle = subtitle
        if (this.token) {
          this.remindDialog = true
        } else {
          this.$store.commit('setLoginDialog', true)
        }
      },
      createPlayerIndicator () {
        if (this.data.url_media) {
          this.playindicator = this.data.url_media.map(item => {
            return {
              type: item.type,
              play: false
            }
          })
        }
      },
      share () {
        this.dialog = true
      },
      getProduct (isInit = true) {
        if (isInit) {
          this.$store.commit('setIsLoading', true)
        }

        if (this.token) {
          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            product_code: this.$route.params.id,
            token: this.token
          }
        } else {
          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            product_code: this.$route.params.id,
          }
        }

        axios.get('/api/v1/pages/product')
          .then(response => {
            this.data = response.data.data
            this.setMetaInfo(this.data, null)

            this.createPlayerIndicator()
            this.$analytics.fbq.event('ViewContent', {
              content_type: 'product_group',
              content_ids: [this.data.product_code],
              value: this.data.selling_price,
              content_name: this.data.product_name,
              currency: 'IDR',
              content_category: this.category
            })

            if (process.env.VUE_APP_PIXEL_TIKTOK) {
              window.ttq.track('ViewContent', { 
                content_id: this.data.product_code,
                value: this.data.selling_price,
                description: this.data.product_name,
                currency: 'IDR',
                quantity: 1,
              })
            }

            if (process.env.VUE_APP_FB_ACCESS_TOKEN) {
              this.apiViewContent()
            }

            window.dataLayer.push({
              'event': 'productClick',
              'ecommerce': {
                'click': {
                  'products': [{
                    'name': this.data.product_name,                      // Name or ID is required.
                    'id': this.data.product_code,
                    'price': this.data.selling_price,
                    'brand': process.env.VUE_APP_NAME,
                    'category': '',
                    'variant': 'All',
                    'size': 'All',
                    'position': '',
                    'url': process.env.VUE_APP_BASE_URL + this.$route.path,
                    'img': this.data.url_image[0],
                    'status': this.data.status
                  }]
                }
              }
            })
          
            window.gtag("event", "view_item", {
              currency: "IDR",
              value: this.data.selling_price,
              items: [
                {
                  item_id: this.data.product_code,
                  item_name: this.data.product_name,
                  item_variant: "All",
                  price: this.data.selling_price,
                }
              ]
            });

            // window.dataLayer.push({
            //   'currencyCode': 'IDR',
            //   'event': 'impressions',
            //   'ecommerce': {
            //     'detail': {
            //       'actionField': {'list': ''},    // 'detail' actions have an optional list property.
            //       'products': [{
            //         'name': this.data.product_name,         // Name or ID is required.
            //         'id': this.data.product_code,
            //         'price': this.data.selling_price,
            //         'brand': process.env.VUE_APP_NAME,
            //         'category': this.category,
            //         'variant': this.data.product_name
            //       }]
            //     }
            //   }
            // });
          })
          .catch(error => {
            this.data = null
            this.message = error.response.data.api_msg
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', this.message)

            if (error.response && error.response.status === 404) { 
              setTimeout(() => {
                this.$router.push({'name': 'Home'})
              },5000)
            }
          })
          .finally(() => {
            if (isInit) {
              this.$store.commit('setIsLoading', false)
            }
          })
      },
      chooseSize (item) {
        this.mySize = item
        this.showSizeList = false
      },
      getOldQuantity (item) {
        let data = this.cart.find(val => val.size.sku_product === item.size.sku_product)
        if (data) {
          return Number(data.quantity)
        } else {
          return 0
        }
      },
      setMySize (item) {
        this.mySize = item
        this.submit()
      },
      apiViewContent () {
        let current_timestamp = Math.floor(new Date() / 1000);
        apiCoversion.post('events?access_token='+ process.env.VUE_APP_FB_ACCESS_TOKEN, {
          data: [
            {
              event_name: 'ViewContent',
              event_time: current_timestamp,
              action_source: 'website',
              user_data: {
                  em: [
                      null
                  ],
                  ph: [
                      null
                  ]
              },
              custom_data: {
                content_type: 'product_group',
                content_ids: [this.data.product_code],
                value: this.data.selling_price,
                content_name: this.data.product_name,
                currency: 'IDR',
                content_category: this.category
              }
            }
          ]
        })
          .then(response => {
            this.coversionResponse = response
          })
          .catch(error => {
            this.coversionResponse = error
          })
      },
      apiAddtoCart (data) {
        let current_timestamp = Math.floor(new Date() / 1000);
        apiCoversion.post('events?access_token='+ process.env.VUE_APP_FB_ACCESS_TOKEN, {
          data: [
            {
              event_name: 'AddToCart',
              event_time: current_timestamp,
              action_source: 'website',
              user_data: {
                  em: [
                      null
                  ],
                  ph: [
                      null
                  ]
              },
              custom_data: {
                content_ids: [data.isBundling ? this.data.product_code : data.size.sku_product],
                num_items: 1,
                content_name: this.data.product_name,
                content_type: 'product_group',
                value: data.isBundling ? this.data.selling_price : data.size.selling_price,
                currency: 'IDR',
                content_category: this.category
              }
            }
          ]
        })
          .then(response => {
            this.coversionResponse = response
          })
          .catch(error => {
            this.coversionResponse = error
          })
      },
      checkIsCountDown () {
        if (this.data && this.data.countdown) {
          this.$store.commit('setSnackbar', true)
          this.$store.commit('setText', 'Kamu tidak dapat melanjutkan pesanan, barang ini masih dalam perhitungan mundur (countdown)')
        } else {
          this.submit()
        }
      },
      submit() {
        this.success = false
        if (this.mySize) {
          let data = {
            data: this.data,
            size: this.mySize,
            quantity: 1,
            url: process.env.VUE_APP_BASE_URL + this.$route.path,
            valid: true,
            isBundling: this.data.is_bundling,
            bundling: null
          }

          this.$store.commit('setIsLoading', true)

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND
          }

          axios.post('/api/v1/orders/add_to_cart', {
              sku_product: data.size.sku_product,
              qty: data.quantity,
              old_qty: this.getOldQuantity(data),
              total_cart_qty: this.cartNumber
          })
            .then(response => {
              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)
              this.$store.commit('addToCart', data)
              this.mySize = null
              this.showSizeList = true
              this.success = true

              if (process.env.VUE_APP_FB_ACCESS_TOKEN) {
                this.apiAddtoCart(data)
              }

              this.$analytics.fbq.event('AddToCart', {
                content_ids: [data.isBundling ? this.data.product_code : data.size.sku_product],
                num_items: 1,
                content_name: this.data.product_name,
                content_type: 'product_group',
                value: data.isBundling ? this.data.selling_price : data.size.selling_price,
                currency: 'IDR',
                content_category: this.category
              })

              if (process.env.VUE_APP_PIXEL_TIKTOK) {
                window.ttq.track('AddToCart', { 
                  content_type: 'product',
                  content_id: data.isBundling ? this.data.product_code : data.size.sku_product,
                  value: data.isBundling ? this.data.selling_price : data.size.selling_price,
                  description: this.data.product_name,
                  currency: 'IDR',
                  quantity: 1
                })
              }

              window.gtag("event", "add_to_cart", {
                currency: "IDR",
                value: data.isBundling ? this.data.selling_price : data.size.selling_price,
                items: [
                  {
                    item_id: data.isBundling ? this.data.product_code : data.size.sku_product,
                    item_name: this.data.product_name,
                    item_variant: data.isBundling ? this.data.product_code : data.size.ukuran,
                    price: data.isBundling ? this.data.selling_price : data.size.selling_price,
                    quantity: 1
                  }
                ]
              });

              window.dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                  'currencyCode': 'IDR',
                  'add': {                                // 'add' actionFieldObject measures.
                    'products': [{                        //  adding a product to a shopping cart.
                      'name': this.data.product_name,
                      'id': data.isBundling ? this.data.product_code : data.size.sku_product,
                      'price': data.isBundling ? this.data.selling_price : data.size.selling_price,
                      'brand': process.env.VUE_APP_NAME,
                      'category': '',
                      'variant':data.size.ukuran,
                      'quantity': 1,
                      'size': data.size.ukuran,
                      'status': this.data.status,
                    }]
                  }
                }
              });        
            })
            .catch(error => {
              this.success = false
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        } else {
          this.$store.commit('setSnackbar', true)
          this.$store.commit('setText', 'Anda belum memilih Size')
        }
      },
      remindMe(item) {
        let data = {
          sku_product: item.variant,
          qty: item.qty,
          message: item.message
        }

        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token
        }

        axios.post('/api/v1/member/wishlist', data)
          .then(response => {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', response.data.message)

            this.updatedIsWishlist = true
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.remindDialog = false
            this.$store.commit('setIsLoading', false)
          })
      },
      openSlider ($event) {
        this.sliderDialog = true
        this.sliderImages = this.data.url_media.filter(item => item.type !== 'video')
        let index = this.sliderImages.findIndex(item => item.url === $event.url)
        this.sliderOptions =  {
          currentPage: index ? index : 0,
          direction: 'horizontal',
          pagination: false,
          loop:true
        }
      },
      addToCartBundling ($event) {
        this.successAddBundling = false
        let data = {
          data: $event.data,
          size: {},
          quantity: 1,
          url: process.env.VUE_APP_BASE_URL + this.$route.path,
          valid: true,
          isBundling: $event.data.is_bundling,
          bundling: $event.bundling
        }

        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', 'Berhasil add to cart')

        setTimeout(() => {
          this.successAddBundling = true
        })

        this.$store.commit('addToCartBundling', data)
      }
    },
    mounted () {
      this.getProduct()

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setActivePage', this.$t('shop.product-detail'))
    }
  }
</script>

<style scoped>   
  .product-detail {
    color: #000000;
    margin: 10%;
  }

  .text {
    font-size: 1.3rem;
  }

  .text-harga {
    color: #da291c;
  }

  .text-sold {
    color: #808080 !important;
    /* font-weight: bold; */
  }

  .text2 {
    font-size: 0.8rem;
  }

  .text-c {
    font-size: 0.8rem;
    color: #000000;
    text-decoration: line-through;
  }

  /* .text-d >>> p {
    font-family: Helvetica;
    font-size: 0.8rem;
    margin: 0;
  } */

  .bordered {
    /* font-family: Helvetica; */
    margin: 10px 0;
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .bordered tr {
    margin-bottom: 10px;
    cursor: pointer;
  }

  .bordered tr:hover {
    font-weight: bolder;
    background-color: #C0C0C0;
  }

  .bordered td {
    padding: 10px 5px; 
  }

  .choosed {
    background-color: #C0C0C0;
  }

  .btn-text {
    color: #FFFFFF;
    text-transform: uppercase;
  }

  .text-d >>> img {
    width: 85%;
    padding: 12px 0;
  }
</style>

<template>
  <div class="bundling-form">
    <v-select
      v-if="list && list.length > 0"
      v-model="product"
      ref="product"
      outlined
      :items="list"
      item-text="product_name"
      item-value="product_code"
      label="Product"
      placeholder="Select Product"
      :hide-details="true"
      @input="getVariant($event)"
      :disabled="isDisabled"
      required
    ></v-select>

    <v-text-field
      v-else
      v-model="empty"
      ref="empty"
      outlined
      label="Product"
      :hide-details="true"
      :disabled="true"
      required
    ></v-text-field>
    <p v-if="variantList.length > 0" class="text"><span>Select Size</span></p>
    <!-- <p v-if="variantList.length > 0" class="text mt-4 mb-0" align="left"> *Select Size </p> -->
    <table class="bordered" v-if="variantList.length > 0">
      <tbody>
        <tr v-for="(item,i) in variantList" :key="i" @click="chooseVariant(item)">
          <td :class="item.sku_product === selectedVariant.sku_product ? 'selected' : 'unselected'">{{ item.ukuran }}</td>
        </tr>
      </tbody>
    </table>
    <p class="mb-4" align="right" v-if="selectedVariant.sku_product">
      <span class="pr-2"> QTY </span>
      <span :class="limitQtyBundling ? 'cart-button-item pr-2 pl-2 cursor-not-allowed' : 'cart-button-item pr-2 pl-2'" small @click="minus()"> - </span>
      <span class="cart-button-item pr-2 pl-2"> {{ qty }} </span>
      <span :class="limitQtyBundling ? 'cart-button-item pr-2 pl-2 cursor-not-allowed' : 'cart-button-item pr-2 pl-2'" small @click="plus()"> + </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'BundlingForm',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    variantList: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectedVariant: {
      type: Object,
      default: () => {
        return {}
      }
    },
    qty: {
      type: Number,
      default: 1
    },
    sameProduct: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    limitQtyBundling: {
      type: Number,
      default: null
    }
  },
  watch: {
    sameProduct (val) {
      if (val) {
        this.product = null
      }
    }
  },
  data () {
    return {
      product: '',
      empty: 'PRODUCT SOLD'
    }
  },
  methods: {
    chooseVariant (item) {
      if (!(this.selectedVariant && this.selectedVariant.sku_product === item.sku_product)){
        this.$emit('selected_variant', item)
      }
    },
    getVariant (item) {
      this.$emit('selected_product', item)
    },
    minus () {
      if (this.limitQtyBundling  === null || this.limitQtyBundling === 0) {
        this.$emit('minus')
      }  
    },
    plus () {
      if (this.limitQtyBundling  === null || this.limitQtyBundling === 0) {
        this.$emit('plus')
      }   
    }
  }
}
</script>

<style scoped>
.bordered {
  text-align: center;
  /* font-family: Helvetica; */
  /* margin: 10px 0 16px 0; */
  margin: 5px 0 16px 0;
  width: 100%;
  /* border-top: 1px solid #000; */
  border-bottom: 1px solid #000;
}

.bordered tr {
  margin-bottom: 10px;
  cursor: pointer;
}

.selected {
  font-weight: bold;
  background-color: #C0C0C0;
}

.bordered td {
  padding: 2px 2px; 
}

/* .cart-button {
  font-size: 1rem;
  color: #000000;
  width: 75px;
  cursor: pointer;
} */

.cart-button-item {
  font-size: 0.8rem;
  border: 1px solid #C0C0C0;
  padding: 5px 5px;
  text-align: center;
  cursor: pointer;
}

.text {
  width: 100%; 
  text-align: left; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 20px 0 0; 
  font-size: 0.8rem;
  padding-left: 12px;
} 

.cursor-not-allowed {
  cursor: not-allowed;
}

.text span { 
  background:#fff; 
  padding: 0 5px; 
}
</style>
